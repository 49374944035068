'use client';

import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import useOnce from '@haaretz/s-use-once';
import s9 from 'style9';

const c = s9.create({
  textFirstLine: {
    marginBottom: space(2),

    ...mq({ from: 's', value: { marginBottom: space(1) } }),
  },
});

const chromePush = fork({
  default: {
    applicationCode: '3624D-2BB40',
    safariWebsitePushID: 'web.com.haaretz.webpush',
    defaultNotificationTitle: 'הארץ',
  },
  tm: {
    applicationCode: '88A17-ACBEA',
    safariWebsitePushID: 'web.com.themarker',
    defaultNotificationTitle: 'TheMarker',
  },
  hdc: {
    applicationCode: '47B50-11004',
    safariWebsitePushID: 'web.com.haaretz.english.webpush',
    defaultNotificationTitle: 'Haaretz',
  },
});

const firstLineText = `<div class="${s9(c.textFirstLine)}">${fork({
  default: 'הירשמו לעדכונים מאתר הארץ',
  tm: 'הירשמו לעדכונים מאתר TheMarker',
})}</div>`;
const sencondLineText =
  '<div>קבלו מאיתנו עדכוני חדשות והמלצות קריאה. רק מה שבאמת חשוב או מעניין</div>';

export default function PushWooshClient() {
  const { applicationCode, safariWebsitePushID, defaultNotificationTitle } = chromePush;

  useOnce(
    () => {
      import('web-push-notifications').then(({ Pushwoosh }) => {
        const pwInstance = new Pushwoosh();

        pwInstance.push([
          'init',
          {
            logLevel: 'info',
            applicationCode,
            safariWebsitePushID,
            defaultNotificationTitle,
            serviceWorkerUrl: '/service-worker.js',
            autoSubscribe: false, // or true. If true, prompts a user to subscribe for pushes upon SDK initialization
            // @ts-expect-error - exists in documentation but not in types
            subscribePopup: {
              enable: true, // (boolean) popup activation
              text: fork({
                default: firstLineText + sencondLineText,
                hdc: '<p><b>Haaretz wants to send you breaking news updates</b></p>Get only the most important updates and interesting reads',
              }),
              askLaterButtonText: fork({
                default: 'בפעם אחרת',
                hdc: 'Another time',
              }), // (string) custom text for the “Ask later” button
              confirmSubscriptionButtonText: fork({ default: 'נשמע טוב', hdc: 'Sounds good' }), // (string) custom text for the “Subscribe” button
              iconUrl: `/v2/${window.__HTZ.bucket}/static/bell.svg`, // (string) custom icon URL
              delay: 1, // (integer) a delay between the page loading and popup appearance
              retryOffset: 2592000, // (integer) an offset in seconds to display the popup again
              overlay: false, // (boolean) enables page overlaying when popup is displayed
              position: 'top', // (string) position on the page. Possible values: ’top' | 'center' | 'bottom’
              fontFamily: fork({ default: 'inherit', hdc: fontStack('commercial') }),

              theme: 'material', // or 'topbar'. A popup theme, see the details below
            },
          },
        ]);

        pwInstance.push([
          'onReady',
          () => {
            window.__HTZ.isPushWooshInitialized = true;
          },
        ]);
      });
    },
    !!(
      applicationCode &&
      defaultNotificationTitle &&
      safariWebsitePushID &&
      typeof window !== 'undefined' &&
      window.__HTZ?.isPushWooshInitialized !== true
    )
  );

  return null;
}
